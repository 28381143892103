/* eslint-disable @typescript-eslint/no-explicit-any */
import { PipeTransform } from '@angular/core';
import { Pipe } from '@angular/core';

@Pipe({
  name: 'pageNumber',
  standalone: true
})
export class PageNumberPipe implements PipeTransform {

  static transform(value: any): any {
    return PageNumberPipe.canTransform(value) ? Number(value) + 1 : value;
  }

  static untransform(value: any): any {
    return PageNumberPipe.canTransform(value) ? Number(value) - 1 : value;
  }

  private static canTransform(value: any): boolean {
    return value !== '' && value !== null && !isNaN(value);
  }

  transform(value: any): any {
    return PageNumberPipe.transform(value);
  }
}
